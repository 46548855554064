import React from 'react'
import '../../App.css';
import BodyMain from '../BodyMain';
import Footer from '../Footer';
import HeroSectionMain from '../HeroSectionMain';


function Home() {
    return (
        <>
            <HeroSectionMain />
            <BodyMain />
            <Footer />
        </>
    )
}

export default Home;